export default {
    fullname: "Peerapat Matheang",
    nickname: "@drafdecode",
    birthyear: 1996,
    tel: "096-756-7844",
    line: "progame69",
    mail: "peerapatmatheang@gmail.com",
    county: "Thailand",
    address: "Bangkok",
    freelance: "Available",
    about: "I’m working as a style planner and web developer. I’m graduated in software development and develop websites for many firms and government agencies for over the past 10 years, I have gained experiences via types of websites.",
    images: "/images/profile/wewebplus-05457-960x960.jpg",
    no_images: "/images/profile/wewebplus-05457-960x960.jpg",
    file_cv: "/images/profile/peerapatmatheang.pdf",
    social: {
        facebook: "https://facebook.com/drafmatong",
        linkedin: "https://www.linkedin.com/in/พีรพัฒน์-มาเที่ยง-0ba861157/",
        github: "https://github.com/drafdecode",
    },
    work:[
        "Software Engineer",
        "Web Developer",
        "FullStack Developer",
        "Research and Development",
    ],
    certificates: [
        {
            title: "Certificate ITPE<br>IP",
            category: "สวทช.",
            images: [
                "/images/profile/itpe1.jpg",
                "/images/profile/itpe2.jpg",
            ],
        },
        {
            title: "Certificate TPQI<br>นักพัฒนาระบบชั้น 3",
            category: "สถาบันคุณวุฒิวิชาชีพ",
            images: [
                "/images/profile/tpqi1.jpg",
                "/images/profile/tpqi2.jpg",
            ],
        },
        {
            title: "Certificate IC3",
            category: "สถาบันคุณวุฒิวิชาชีพ",
            images: [
                "/images/profile/ic3.jpg",
            ],
        },
    ],
    works: [
        {
            title: "BDN",
            url: "https://bdn.go.th/th/home",
            images: "/images/profile/bdn.png",
        },
        {
            title: "Neda",
            url: "https://www.neda.or.th",
            images: "/images/profile/neda.png",
        },
        {
            title: "NBTC",
            url: "https://www.nbtc.go.th",
            images: "/images/profile/nbtc.png",
        },
        {
            title: "Mnre",
            url: "http://www.mnre.go.th",
            images: "/images/profile/mnre.png",
        },
        {
            title: "Mssu",
            url: "http://www.ms.su.ac.th/th/home",
            images: "/images/profile/mssu.png",
        },
        {
            title: "Scotch",
            url: "https://shop.scotchthailand.co.th/th/home",
            images: "/images/profile/scotch.png",
        },
        {
            title: "Betagro",
            url: "http://www.betagro.com/intro",
            images: "/images/profile/betagro.png",
        },
        {
            title: "Intelligent",
            url: "https://www.intelligent.co.th",
            images: "/images/profile/intelligent.png",
        },
        {
            title: "Sam",
            url: "https://sam.in.th/th",
            images: "/images/profile/sam.png",
        },
        {
            title: "Stop",
            url: "https://stop.in.th/th",
            images: "/images/profile/stop.png",
        },
        {
            title: "Portochino",
            url: "http://www.portochino.com",
            images: "/images/profile/portochino.png",
        },
        {
            title: "Vivasoc",
            url: "https://www.vivasoc.com",
            images: "/images/profile/vivasoc.png",
        },
        {
            title: "Biowoman",
            url: "https://www.biowoman.co.th",
            images: "/images/profile/biowoman.png",
        },
        {
            title: "PBX",
            url: "https://www.pbxsangoma.com",
            images: "/images/profile/pbxsangoma.png",
        },
        {
            title: "K-Con",
            url: "https://k-con.co.th",
            images: "/images/profile/k-con.png",
        },
        {
            title: "Third500",
            url: "https://third500.com/home",
            images: "/images/profile/third500.png",
        },
        {
            title: "SKG",
            url: "https://sakolgroup.co.th/th/home",
            images: "/images/profile/sakolgroup.png",
        },
        {
            title: "GPM",
            url: "https://gpm.co.th/th/home",
            images: "/images/profile/gpm.png",
        },
    ],
    awards: {
        fullname: "Peerapat Matheang",
        images: "/images/profile/wewebplus-05971-172x172.jpg",
        work: "FullStack Developer",
        desc: "All awards received since college.",
        list: [
            {
                title: "04 September 2016",
                desc: "ACM ICPC 2016 รองชนะเลิศอันดับ 1 ระดับภูมิภาคเหนือ",
            },
            {
                title: "17 - 18 October 201",
                desc: "ACM ICPC 2016 ระดับประเทศ Thailand National On-site",
            },
            {
                title: "23 June 2017",
                desc: "Startup Thailand 2017",
            },
            {
                title: "01 April 2018",
                desc: "Startup Thailand 2018",
            },
            {
                title: "05 - 07 May 2017",
                desc: "NDX-Northern Digital Expo 2017",
            },
            {
                title: "06 February 2017",
                desc: "การแข่งขันพัฒนาโปรแกรมคอมพิวเตอร์แห่งประเทศไทย ครั้งที่ 19 (NSC 2017)",
            },
            {
                title: "14 February 2018",
                desc: "การแข่งขันพัฒนาโปรแกรมคอมพิวเตอร์แห่งประเทศไทย ครั้งที่ 20 (NSC 2018)",
            },
            {
                title: "30 November 2016",
                desc: "UP Disrupt 2016",
            },
        ],
    },
    resume: {
        experiences: [
            {
                isActive: true,
                year: "2020 - Present",
                subject: "FullStack Developer",
                title: "289 Production House Co,. Ltd.",
                desc: "It is a web development company with government clients and private companies.",
            },
            {
                isActive: true,
                year: "2019 - Present",
                subject: "CEO",
                title: "Decodethailand Team.",
                desc: "Work as a freelancer about website development.",
            },
            {
                isActive: false,
                year: "2019 - 2020",
                subject: "Web Developer /<br> Research and Development",
                title: "Wewebplus Co,. Ltd.",
                desc: "It is a web development company with government clients and private companies.",
            },
        ],
        educations: [
            {
                isActive: false,
                year: "2015 - 2019",
                subject: "University Of Phayao",
                title: "Phayao, Thailand",
                desc: "Software Engineering.",
            },
            {
                isActive: false,
                year: "2009 - 2015",
                subject: "Phromphiramwittaya School",
                title: "Phitsanulok, Thailand",
                desc: "Being a high school, I studied science and mathematics.",
            },
        ],
    },
    skills: [
        {
            title: "The Ability to Communicate",
            scale: "70%",
        },
        {
            title: "Always seeking new knowledge",
            scale: "90%",
        },
        {
            title: "Reasoning and Problem Solving",
            scale: "85%",
        },
        {
            title: "Enthusiasm for work",
            scale: "75%",
        },
    ],
    languages: [
        {
            title: "Thai",
            scale: "90%", //10-100
        },
        {
            title: "English",
            scale: "30%",
        },
    ],
    knowledges: [
        "Adaptability",
        "Strong Research Skills",
        "The Ability to Get Focused",
        "Communicate",
        "The Ability to Meet Deadlines",
        "Organizational Skills",
        "Always seeking new knowledge",
    ],
}